import type { IModuleStoryTileGrid } from "@contentful-api/types/contentful";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import type { IModuleRenderedProps } from "@lib/contentful/modules-renderer/ModuleCreator";
import { StoryGrid } from "@ui/components/editorial/story-grid/StoryGrid";
import { StoryTile } from "@ui/components/editorial/story-tile/StoryTile";
import { Box } from "@ui/components/layout/box/Box";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { Fragment } from "react";
import { Link } from "@components/cms/link/Link";

interface IStoryTileGridProps extends IModuleRenderedProps<IModuleStoryTileGrid> {}

export const StoryTileGrid: FC<IStoryTileGridProps> = ({ module }) => {
    const gridTitle = module?.fields?.title;
    const gridId = module?.sys.id;
    const gridDescription = module?.fields?.description;
    const textAlign = module?.fields?.textAlignment;
    const storyTiles = module?.fields?.storyTiles;
    const storyTileGridWidth = module?.fields?.storyTileGridWidth || "full width";
    const columns = storyTiles?.length;
    const { getMultiple } = useMicrocopy();
    const microcopies = (() => {
        return getMultiple(CommonCMS.global, [
            CommonCMS.generic.readMore,
            CommonCMS.generic.readLess,
        ]);
    })();

    return (
        <Box
            data-contentful-entry-id={module?.sys?.id}
            data-contentful-field-id={"moduleStoryTileGrid"}
        >
            <StoryGrid
                columns={columns}
                title={gridTitle}
                description={gridDescription}
                textAlign={textAlign}
                width={storyTileGridWidth}
            >
                {storyTiles?.map?.((storyTile, i) => {
                    const fields = storyTile?.fields;
                    const id = storyTile?.sys?.id;
                    const link = fields?.link;
                    const image = fields?.image;
                    const imageSrc = image?.fields?.file?.url;
                    const title = fields?.title;
                    const imageAlt = image?.fields?.description;
                    const linkDescription = fields?.description;
                    const hasLink = !isEmpty(link);
                    const linkTitle = link?.fields?.label ?? title;
                    const localMicro = (() => {
                        return getMultiple(
                            CommonCMS.global,
                            [CommonCMS.generic.readMoreAria, CommonCMS.generic.readLessAria],
                            { replacements: { title } }
                        );
                    })();
                    return (
                        <Fragment key={`${id} - ${i}`}>
                            {hasLink ? (
                                <Link
                                    link={link}
                                    w="full"
                                    h="fit-content"
                                    textDecoration="none"
                                    _hover={{
                                        textDecoration: "none",
                                    }}
                                    onClick={() =>
                                        AnalyticsClient.selectPromotion({
                                            image: imageSrc,
                                            title: title,
                                            index: i,
                                            id: gridId + id,
                                        })
                                    }
                                >
                                    <StoryTile
                                        ariaLess={localMicro[CommonCMS.generic.readLessAria]}
                                        ariaMore={localMicro[CommonCMS.generic.readMoreAria]}
                                        showLessLabel={microcopies[CommonCMS.generic.readLess]}
                                        showMoreLabel={microcopies[CommonCMS.generic.readMore]}
                                        imageSrc={imageSrc}
                                        imageAlt={imageAlt}
                                        title={title}
                                        description={linkDescription}
                                        columns={columns}
                                        hasLink={hasLink}
                                        onInView={() =>
                                            AnalyticsClient.viewPromotion({
                                                image: imageSrc,
                                                title: title,
                                                index: i,
                                                id: gridId + id,
                                            })
                                        }
                                        linkTitle={linkTitle}
                                    />
                                </Link>
                            ) : (
                                <StoryTile
                                    showLessLabel={microcopies[CommonCMS.generic.readLess]}
                                    showMoreLabel={microcopies[CommonCMS.generic.readMore]}
                                    imageSrc={imageSrc}
                                    imageAlt={imageAlt}
                                    title={title}
                                    description={linkDescription}
                                    columns={columns}
                                    hasLink={hasLink}
                                    linkTitle={linkTitle}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </StoryGrid>
        </Box>
    );
};
